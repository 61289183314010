<template>
  <div class="exam-content-ro">
    <div class="title">
      The text boxes in the left panel have been placed in a random order.
      Restore the original order by dragging the text boxes from the left panel
      to the right panel.
    </div>
    <div class="body">
      <Drag :list="detail.check.items" :onInput="handleCheck" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Drag from '../../components/drag'
import api from '@/api/exam'

export default {
  components: {
    Drag
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  mounted() {
    this.SetTimeType({ type: 'READ' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (this.checkList && this.checkList.length > 0) {
        this.handleSave()
        return true
      }
      return false
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [...this.checkList]
      })
      this.onFinish()
    },

    handleCheck(list) {
      this.checkList = list
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-ro {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    width: 1014px;
    margin: 20px auto;
  }
}
</style>
