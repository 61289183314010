<template>
  <div class="exam-recorded-style">
    <div class="title">Recorded Answer</div>
    <div class="status">
      <label>Current status:</label>
    </div>
    <div class="time">
      <template v-if="currentTime > 0">
        Beginning in {{ currentTime }} seconds
      </template>
      <template v-else="currentTime === 0">
        <template v-if="status === 'recording'">Recording</template>
        <template v-if="status === 'end'">Complete</template>
      </template>
    </div>
    <div class="progress">
      <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
    </div>

    <audio :src="require('@/assets/ding.mp3')" ref="audio" />

    <AudioTip :visible="tipVisible" @closeDialog="tipVisible = false" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Recorder, { ENCODE_TYPE } from 'recorderx'
import * as upload from '@/utils/upload'
import AudioTip from '@/components/ExercisesContent/components/audio-open'

//生成随机数
const getMath = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export default {
  components: {
    AudioTip
  },
  props: {
    time: {
      type: Number,
      default: 30
    },
    showTips: {
      type: Boolean,
      default: true
    },
    maxTime: {
      type: Number,
      default: 60
    },
    onUpload: {
      type: Function,
      default: () => {}
    },
    onTime: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      currentTime: 0,
      status: '',
      recordTime: 0,
      audioFile: null,
      audioUrl: '',

      tipVisible: false
    }
  },
  computed: {
    progress() {
      return (this.recordTime / this.maxTime) * 100
    }
  },
  mounted() {
    if (!navigator.mediaDevices.getUserMedia) {
      this.$layer.msg("<div class='error-icon'></div><div>当前不支持录音</div>")
      return
    }

    this.currentTime = this.time
    this.cutDown()
  },
  beforeDestroy() {
    clearInterval(this.recordTimeId)
    clearInterval(this.timeId)
  },
  methods: {
    ...mapActions('exam', ['SetLoading']),
    stop() {
      return this.stopRecorder()
    },

    cutDown() {
      this.timeId = setInterval(() => {
        this.currentTime -= 1
        this.onTime(this.currentTime)
        if (this.currentTime === 0) {
          clearInterval(this.timeId)
          this.endCountDown()
        }
      }, 1000)
    },
    endCountDown() {
      this.canRecorder(this.palyTips)
    },
    cutRecordTime() {
      this.recordTimeId = setInterval(() => {
        this.recordTime += 1
        console.log(this.recordTime, this.maxTime)
        if (this.recordTime >= this.maxTime) {
          clearInterval(this.recordTimeId)
          this.stopRecorder()
        }
      }, 1000)
    },

    onError() {
      this.tipVisible = true
      // this.$layer.msg(
      //   "<div class='error-icon'></div><div>您的麦克风权限未开启，开启后才能正常录音。</div>"
      // )
    },
    canRecorder(onSuccess) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((...argus) => onSuccess(argus))
        .catch((...argus) => this.onError(argus))
    },
    palyTips() {
      if (this.showTips) {
        this.$refs.audio && this.$refs.audio.play()
        setTimeout(() => {
          this.startRecorder()
        }, 1000)
      } else {
        this.startRecorder()
      }
    },

    // 开始录音
    startRecorder() {
      if (this.recording) {
        return false
      }

      this.$emit('startRecord')
      this.rc = new Recorder()
      this.cutRecordTime()
      this.$nextTick(() => {
        this.rc
          .start()
          .then(() => {
            console.log('start recording')
          })
          .catch((error) => {
            console.log('Recording failed.', error)
          })
        this.status = 'recording'
      })
    },
    stopRecorder() {
      if (this.recordTime <= 1) {
        this.$layer.msg("<div class='error-icon'></div><div>录音时间过短</div>")
        this.status = 'pause'
        return false
      }

      this.rc.pause()
      this.status = 'end'

      this.SetLoading(true)

      clearInterval(this.recordTimeId)
      clearInterval(this.timeId)

      this.getRecorder()

      return true
    },

    // 获得音频
    getRecorder() {
      const wav = this.rc.getRecord({
        encodeTo: ENCODE_TYPE.WAV
        // compressible: true
      })
      this.audioFile = wav
      this.uploadFile()
    },
    uploadFile() {
      const name = getMath() + getMath() + getMath() + '.wav'
      upload.file({
        file: this.audioFile,
        fileName: name,
        onSuccess: ({ data }) => {
          this.audioUrl = data.oss_url

          this.onUpload(this.audioUrl)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exam-recorded-style {
  width: 300px;
  border-radius: 5px;
  padding: 24px 20px;
  color: #1f1f40;
  font-size: 14px;
  border: 2px solid #a4afb7;
  background-color: #e8f1ff;
  box-sizing: border-box;
  .title {
    font-weight: bold;
    text-align: center;
  }
  .status {
    margin-top: 16px;
    > label {
      font-weight: bold;
    }
  }
  .time {
    margin-top: 8px;
    height: 16px;
  }
  .progress {
    margin-top: 20px;
    height: 10px;
    position: relative;
    background-color: #c7cade;
    border-radius: 2px;
    .progress-bar {
      background-color: #3b86ff;
      height: 10px;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 1s linear;
    }
  }
}
</style>
