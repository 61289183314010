<template>
  <div class="exam-detail">
    <components
      ref="content"
      v-if="currentStep"
      :is="currentStep.com"
      :data="currentStep.data"
      :onFinish="forceNextStep"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api/exam'

import CheckHeadset from './check/headset'
import CheckMicrophone from './check/microphone'
import CheckKeyboard from './check/keyboard'
import CheckIntroduction from './check/introduction'
import CheckOverview from './check/overflow'
import CheckSpeak from './check/speaking'
import Start from './check/start'
import Part1Check from './part/part1check'
import Part2Check from './part/part2check'
import OptionalBreak from './part/optionalBreak'
import Part3Check from './part/part3check'
import Finish from './part/finish'

import Content from './content/index'

const sortFilterExam = (ids, list, data, comName = 'Content') => {
  return ids
    .reduce((arr, id) => {
      return arr.concat(list.filter((m) => m.cate_sub === id))
    }, [])
    .map((item) => ({
      data: {
        ...data,
        item: item
      },
      com: comName
    }))
}

export default {
  components: {
    CheckHeadset,
    CheckMicrophone,
    CheckKeyboard,
    CheckIntroduction,
    CheckOverview,
    CheckSpeak,
    Start,
    Part1Check,
    Part2Check,
    OptionalBreak,
    Part3Check,

    Content,
    Finish
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    examDetail: {
      type: Object,
      default: () => {}
    },
    examInfo: {
      type: Object,
      default: () => {}
    },
    onStep: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      step: 0
    }
  },
  computed: {
    examList() {
      return this.info.exam_item || []
    },
    part1StepList() {
      const cateIds = [36, 37, 38, 39, 40, 45, 46]
      return (Number(this.info.type) === 1
        ? [
            {
              data: null,
              com: 'Start'
            }
          ]
        : []
      ).concat(
        sortFilterExam(cateIds, this.examList, {
          info: this.info,
          examInfo: this.examInfo
        })
      )
    },
    part2StepList() {
      const cateIds = [42, 43, 44, 52, 53]
      return (Number(this.info.type) === 1
        ? [
            {
              data: null,
              com: 'Part2Check'
            }
          ]
        : []
      ).concat(
        sortFilterExam(cateIds, this.examList, {
          info: this.info,
          examInfo: this.examInfo
        })
      )
    },
    optionalBreakStepList() {
      return [
        {
          data: null,
          com: 'OptionalBreak'
        }
      ]
    },
    part3StepList() {
      const cateIds = [28, 29, 30, 31, 32, 33, 34, 35]
      return (Number(this.info.type) === 1
        ? [
            {
              data: null,
              com: 'Part3Check'
            }
          ]
        : []
      ).concat(
        sortFilterExam(cateIds, this.examList, {
          info: this.info,
          examInfo: this.examInfo
        })
      )
    },
    startStepList() {
      return [
        {
          data: null,
          com: 'Part1Check'
        }
      ]
    },
    checkStepList() {
      return [
        {
          data: null,
          com: 'CheckHeadset'
        },
        {
          data: null,
          com: 'CheckMicrophone'
        },
        {
          data: null,
          com: 'CheckKeyboard'
        },
        {
          data: null,
          com: 'CheckIntroduction'
        },
        {
          data: null,
          com: 'CheckOverview'
        },
        {
          data: null,
          com: 'CheckSpeak'
        }
      ]
    },
    allStep() {
      return Number(this.info.type) === 1
        ? []
            .concat(this.checkStepList)
            .concat(this.startStepList)
            .concat(this.part1StepList)
            .concat(this.part2StepList)
            .concat(this.optionalBreakStepList)
            .concat(this.part3StepList)
            .concat([
              {
                data: null,
                com: 'Finish'
              }
            ])
        : []
            .concat(this.part1StepList)
            .concat(this.part2StepList)
            .concat(this.part3StepList)
            .concat([
              {
                data: null,
                com: 'Finish'
              }
            ])
    },
    currentStep() {
      return this.allStep[this.step]
    }
  },
  watch: {
    step: {
      immediate: true,
      handler() {
        this.onStep({
          step: this.step,
          total: this.allStep.length,
          currentStep: this.currentStep,
          allStep: this.allStep
        })

        if (this.step > 0) {
          api.saveExamIndex({
            my_exam_id: this.examInfo.my_exam_id,
            index: this.step
          })
        }
      }
    },
    allStep: {
      immediate: true,
      handler() {
        this.onStep({
          step: this.step,
          total: this.allStep.length,
          currentStep: this.currentStep,
          allStep: this.allStep
        })
      }
    },
    examDetail: {
      deep: true,
      handler() {
        this.step = this.examDetail.last_view_id
        // const stepInfo = (this.examDetail.exam_item || [])
        //   .filter((m) => m.is_done === 1)
        //   .slice(-1)[0]
        // this.step = stepInfo
        //   ? this.allStep.findIndex(
        //       (m) =>
        //         m.data &&
        //         m.data.item &&
        //         m.data.item.id &&
        //         m.data.item.id === stepInfo.id
        //     )
        //   : 0
      }
    }
  },
  mounted() {
    this.SetEvent({
      type: 'next',
      func: this.handleNext.bind(this)
    })
    this.SetEvent({
      type: 'nextPart',
      func: this.handleNextPart.bind(this)
    })
  },
  methods: {
    ...mapActions('exam', ['SetLoading', 'SetEvent']),
    nextStep() {
      if (this.$refs.content && this.$refs.content.submit) {
        this.SetLoading(true)
        // 倒计时结束前可以进入下一步
        if (this.$refs.content.submit()) {
          return true
        }
      }

      this.forceNextStep()
    },
    forceNextStep() {
      this.step = Math.min(this.allStep.length - 1, this.step + 1)
      setTimeout(() => {
        this.SetLoading(false)
      }, 1000)
    },
    prevStep() {
      this.step = Math.max(0, this.step - 1)
    },

    handleNext: () => {
      this.forceNextStep()
    },
    handleNextPart() {
      this.$confirm(
        'Your time for this session is up. Please move to next session.',
        'Time up',
        {
          showCancelButton: false,
          confirmButtonText: 'OK'
        }
      ).then(() => {
        const speak = [36, 37, 38, 39, 40]
        const write = [45, 46]
        const read = [42, 43, 44, 52, 53]
        const linsten = [28, 29, 30, 31, 32, 33, 34, 35]
        const typeList = [speak, write, read, linsten]
        const currentType = this.currentStep.data.item.cate_sub

        const currentIndex = typeList.findIndex((m) => m.includes(currentType))
        if (currentIndex === 3) {
          this.step = this.allStep.length - 2
        } else {
          const nextType = typeList[currentIndex + 1][0]
          this.step = this.allStep.findIndex(
            (m) => m.data && m.data.item && m.data.item.cate_sub === nextType
          )
        }
      })
    },

    async saveFinish() {
      this.$confirm('Are you sure to submit for scoring?', 'Finish', {
        showCancelButton: false,
        confirmButtonText: 'Submit'
      }).then(async () => {
        this.SetLoading(true)
        if (this.$refs.content && this.$refs.content.submit) {
          this.$refs.content.submit()
        }

        await api.finish({
          my_exam_id: this.examInfo.my_exam_id
        })

        this.forceNextStep()

        this.SetLoading(false)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exam-detail {
}
</style>
