<template>
  <div class="exam-content-asq">
    <div class="title">
      You will hear a question. Please give a simple and short answer. Often
      just one or a few words is enough.
    </div>
    <div class="record-wrap">
      <div>
        <div class="audio">
          <Audio
            ref="record"
            :time="3"
            :url="detail.audio.url"
            :onInit="handleAudioInit"
          />
        </div>
        <div class="record">
          <Record
            v-if="recoredWaitTime > 0"
            :time="recoredWaitTime"
            :maxTime="10"
            :onUpload="handleUplaod"
            :onTime="handleRecordTime"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Record from '../../components/record'
import Audio from '../../components/audio'
import api from '@/api/exam'

export default {
  components: {
    Record,
    Audio
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      recoredWaitTime: 0,
      recordCurrentTime: -1
    }
  },
  mounted() {
    this.SetTimeType({ type: 'SPEAK' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (
        this.recordCurrentTime === 0 &&
        this.$refs.record &&
        this.$refs.record.stop
      ) {
        this.$refs.record.stop()
        return true
      }

      return false
    },

    handleRecordTime(time) {
      this.recordCurrentTime = time
    },

    handleAudioInit(audioTime, waitTime) {
      this.recoredWaitTime = Math.round(audioTime + waitTime)
    },
    async handleUplaod(url) {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        oss_url: url
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-asq {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 65px;
      width: 260px;
    }
    .audio {
      margin-top: 24px;
    }
  }
  .record {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}
</style>
