<template>
  <div class="exam-content-hiw">
    <div class="title">
      You will hear a recording. Below is a transcription of the recording. Some
      words in the transcription differ from what the speaker said. Please click
      on the words that are different.
    </div>
    <div class="body">
      <div class="audio">
        <Audio :url="detail.audio.url" :time="10" />
      </div>
      <div class="content">
        <span
          v-for="(item, index) of detail.content.article"
          :key="`${index}_${item.text}`"
          :class="{ select: selectIndex.includes(index) }"
          @click="handleSelect(index)"
        >
          {{ item.text }}&nbsp;
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api/exam'
import Audio from '../../components/audio'

export default {
  components: {
    Audio
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectIndex: []
    }
  },
  mounted() {
    this.SetTimeType({ type: 'LISTEN' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (this.selectIndex && this.selectIndex.length > 0) {
        this.handleSave()
        return true
      }
      return false
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: this.selectIndex
      })
      this.onFinish()
    },

    handleSelect(index) {
      if (this.selectIndex.includes(index)) {
        this.selectIndex = this.selectIndex.filter((m) => m !== index)
      } else {
        this.selectIndex = this.selectIndex.concat([index])
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-hiw {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    width: 1014px;
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ipt {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 14px;
    display: flex;
    justify-content: center;
  }
  .content {
    margin-top: 24px;
    line-height: 20px;
    > span {
      cursor: pointer;
    }
    .select {
      background-color: #fff781;
    }
  }
}
</style>
