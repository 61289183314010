<template>
  <div class="exam-content">
    <components
      v-if="detail"
      ref="com"
      :is="comName"
      :itemData="itemData"
      :detail="detail"
      :examInfo="examInfo"
      :infoData="infoData"
      :onFinish="onFinish"
    />
  </div>
</template>

<script>
import { apiGetWebQuestion } from '@/api/api.js'

import RA from './type/ra'
import RS from './type/rs'
import DI from './type/di'
import RL from './type/rl'
import ASQ from './type/asq'
import SWT from './type/swt'
import WE from './type/we'

import MCM from './type/mcm'
import MCS from './type/mcs'
import RO from './type/ro'
import RFIB from './type/rfib'
import WRFIB from './type/wrfib'

import SST from './type/sst'
import LMCM from './type/lmcm'
import LFIB from './type/lfib'
import HCS from './type/hcs'
import LMCS from './type/lmcs'
import SMW from './type/smw'
import HIW from './type/hiw'
import WFD from './type/wfd'

export default {
  components: {
    RA,
    RS,
    DI,
    RL,
    ASQ,
    SWT,
    WE,
    MCM,
    MCS,
    RO,
    RFIB,
    WRFIB,
    SST,
    LMCM,
    LFIB,
    HCS,
    LMCS,
    SMW,
    HIW,
    WFD
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      detail: null
    }
  },
  computed: {
    itemData() {
      return this.data.item
    },
    infoData() {
      return this.data.info
    },
    examInfo() {
      return this.data.examInfo
    },
    comName() {
      return {
        36: 'RA',
        37: 'RS',
        38: 'DI',
        39: 'RL',
        40: 'ASQ',
        45: 'SWT',
        46: 'WE',

        42: 'MCM',
        43: 'MCS',
        44: 'RO',
        52: 'RFIB',
        53: 'WRFIB',

        28: 'SST',
        29: 'LMCM',
        30: 'LFIB',
        31: 'HCS',
        32: 'LMCS',
        33: 'SMW',
        34: 'HIW',
        35: 'WFD'
      }[this.itemData.cate_sub]
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchDetail()
      }
    }
  },
  methods: {
    submit() {
      if (this.$refs.com && this.$refs.com.submit) {
        return this.$refs.com.submit()
      }
    },

    fetchDetail() {
      this.detail = null
      apiGetWebQuestion({
        id: this.itemData.gre_try_id
      }).then((res) => {
        this.detail = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content {
}
</style>
