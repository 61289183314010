<template>
  <div class="exam-content-mcm">
    <div>
      <pre v-html="detail.check.stem"></pre>
    </div>
    <div>
      <div class="title">
        Read the text and answer the question by selecting all the correct
        responses. More than one response is correct.
      </div>
      <div class="answer-title">{{ detail.check.title }}</div>
      <Checkbox
        :list="detail.check.options"
        class="check-wrap"
        :onInput="handleCheck"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Checkbox from '../../components/checkbox'
import api from '@/api/exam'

export default {
  components: {
    Checkbox
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  mounted() {
    this.SetTimeType({ type: 'READ' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (this.checkList && this.checkList.length) {
        this.handleSave()
        return true
      }
      return false
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [...this.checkList]
      })
      this.onFinish()
    },

    handleCheck(list) {
      this.checkList = list
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-mcm {
  color: #1f1f40;
  display: flex;
  > div {
    flex: 1;
    margin-left: 50px;
    &:first-child {
      margin-left: 0;
    }
  }
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    width: 1014px;
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .answer-title {
    margin-top: 24px;
  }
  .check-wrap {
    margin-top: 10px;
  }
}
</style>
