<template>
  <div class="exam-part-check">
    <p>
      Read the prompt below. In 25 seconds, you must reply in your own words, as
      naturally and clearly as possible. You have 30 seconds to record your
      response. Your response will be sent together with your score report to
      the institutions selected by you.
    </p>
    <div class="info">
      <p>
        Please introduce yourself. For example, you could talk about one or more
        of the following:
      </p>
      <p>- Your interests</p>
      <p>- Your plans for future study</p>
      <p>- Why you want to study abroad</p>
      <p>- Why you need to learn English</p>
      <p>- Why you chose this test</p>
    </div>

    <div class="record">
      <Record time="25" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Record from '../components/record'

export default {
  components: {
    Record
  },

  mounted() {
    this.SetTimeType({ type: 'NONE' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.exam-part-check {
  margin-top: 20px;
  p {
    color: #1f1f40;
    font-size: 14px;
    line-height: 20px;
    > span {
      color: #ff606d;
      font-weight: bold;
    }
  }
  .info {
    margin-top: 10px;
  }
  .record {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
</style>
