<template>
  <div class="exam-content-ra">
    <div class="title">
      Look at the text below. In 40 seconds, you must read this text aloud as
      naturally and clearly as possible. You have 40 seconds to read aloud.
    </div>
    <div class="record">
      <Record
        ref="record"
        :time="40"
        :maxTime="40"
        :onUpload="handleUplaod"
        :onTime="handleRecordTime"
      />
    </div>
    <div>
      <pre v-html="content"></pre>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Record from '../../components/record'
import api from '@/api/exam'

export default {
  components: {
    Record
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      recordCurrentTime: -1
    }
  },
  computed: {
    content() {
      return (this.detail.content_text || '')
        .replace(/\(/g, '')
        .replace(/\//g, '')
        .replace(/\s+/g, ' ')
    }
  },
  mounted() {
    this.SetTimeType({ type: 'SPEAK' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (
        this.recordCurrentTime === 0 &&
        this.$refs.record &&
        this.$refs.record.stop
      ) {
        this.$refs.record.stop()
        return true
      }

      return false
    },

    handleRecordTime(time) {
      this.recordCurrentTime = time
    },

    async handleUplaod(url) {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        oss_url: url
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-ra {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  pre {
    margin-top: 10px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
}
</style>
