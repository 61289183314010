<template>
  <div class="check-headset">
    <div class="title">
      <span>Headset Check</span>
      <span class="time">This screen is not timed</span>
    </div>
    <div class="body">
      <div class="info">
        <p>
          This is an opportunity to check that your headset is working
          correctly.
        </p>
        <p>
          1 Put your headset on and adjust it so that it fits comfortably over
          your ears.
        </p>
        <p>
          2 When you are ready, click on the <span>Play</span> button. You will
          hear a short recording.
        </p>
        <p>
          3 If you do not hear anything in your headphones while the status
          reads <span>Playing</span>, raise your hand to get the attention of
          the Test Administrator.
        </p>
        <AudioStyle
          url="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1620196448704589373165.mp3"
          class="audio"
          :volume="30"
          :progress="0"
        />
        <p>
          - During the mock you will not have <span>Play</span> and
          <span>Stop</span> buttons. The audio recording will start playing
          automatically.
        </p>
        <p>
          - Please do not remove your headset. You should wear it throughout the
          test.
        </p>
      </div>
      <img
        src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1619415863081354202283.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AudioStyle from '../components/audioTest'

export default {
  components: {
    AudioStyle
  },

  mounted() {
    this.SetTimeType({ type: 'NONE' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.check-headset {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebf2;
    padding: 30px 0 10px;
    > span {
      color: #1f1f40;
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    align-items: flex-start;
    > img {
      min-width: 214px;
    }
    .info {
      flex: 1;
      align-items: flex-start;
      margin-right: 20px;
      .audio {
        margin-top: 10px;
        margin-bottom: 20px;
      }
      p {
        color: #1f1f40;
        font-size: 14px;
        line-height: 30px;
        > span {
          color: #ff606d;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
