<template>
  <div class="exam-content-lfib">
    <div class="title">
      You will hear a recording. Type the missing words in each blank.
    </div>
    <div class="body">
      <div class="audio">
        <Audio :url="detail.audio.url" :time="7" />
      </div>
      <div class="content">
        <span v-for="(item, index) in textContentList" :key="index">
          <template v-if="!item.isEmpty && item.text.indexOf('_') < 0">
            <span>{{ item.text }}</span>
            <span v-html="'&nbsp;'"></span>
            <br v-if="/[\n\r]/g.test(item.text)" />
          </template>
          <el-input
            v-model="greFillSelected[item.count]"
            v-if="item.isEmpty && !item.truth"
            spellcheck="false"
          ></el-input>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api/exam'
import { symbolsFix } from '@/utils/parctice'
import Audio from '../../components/audio'
import { cloneDeep } from 'lodash'

export default {
  components: {
    Audio
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      greFillSelected: []
    }
  },
  computed: {
    textContentList() {
      if (!this.detail.content_text) {
        return []
      }
      const content = symbolsFix(this.detail.content_text)

      const answerList = (this.answerDetail
        ? this.answerDetail.answer.article
        : []
      ).filter((m) => /_/g.test(m.text))
      let optCount = -1
      return content
        .replace(/\r.?\n/g, '\n')
        .split(/[^\S\r\n]/g)
        .map((text) => {
          const isIpt = /_/g.test(text)
          if (isIpt) {
            optCount += 1
          }
          const answerData = answerList[optCount] || {}
          return {
            text: text,
            lang: isIpt ? 'default' : 'en',
            isEmpty: isIpt,
            ...(isIpt ? { count: optCount } : {}),
            ...(isIpt ? answerData : {})
          }
        })
    }
  },
  watch: {
    textContentList() {
      this.textContentList.forEach((item) => {
        if (item.text === '_') {
          this.greFillSelected.push([])
        }
      })
    }
  },
  mounted() {
    this.SetTimeType({ type: 'LISTEN' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (
        this.greFillSelected.reduce(
          (flag, item) => flag || !!(item && item.length),
          false
        )
      ) {
        this.handleSave()
        return true
      }

      return false
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: this.greFillSelected
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-lfib {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    width: 1014px;
    margin: 20px auto;
    .audio {
      display: flex;
      justify-content: center;
    }
    .content {
      margin-top: 24px;
      line-height: 26px;
      white-space: normal;
      display: flex;
      flex-wrap: wrap;
      .el-input {
        width: auto;
        margin: 0 5px;
        &.is-focus .el-input__inner {
          border-color: #707070;
        }
      }
      .green {
        .el-input__inner {
          color: #36c626;
        }
      }
      .red {
        .el-input__inner {
          color: #ff1a2e;
        }
      }
      .el-input__inner {
        width: 180px;
        height: 22px;
        font-size: 14px;
        color: #1f1f40;
        line-height: 22px;
        border: 1px solid #707070;
        border-radius: 0;
      }
      .el-input__suffix-inner {
        margin-top: 4px;
        display: inline-block;
        .el-select__caret {
          font-size: 0;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAAXNSR0IArs4c6QAAAENJREFUCB1jYEADcnIODSCMJszAjCwAUsDIyFAPxA78/AqMHz8+OACThyuEKYJJoCsGK0RXhE0xIy5FMMUg+v9/hkYAy2gWfwBwB8UAAAAASUVORK5CYII=);
          background-repeat: no-repeat;
          height: 16px;
          background-position: center;
        }
      }
      .el-select {
        // margin: 0 6px;
      }
    }
  }

  ::v-deep .el-input__inner {
    width: 180px;
    height: 22px;
    font-size: 14px;
    color: #1f1f40;
    line-height: 22px;
    border: 1px solid #707070;
    border-radius: 0;
  }
}
</style>
