<template>
  <div class="check-headset">
    <div class="title">
      <span>Test Introduction</span>
      <span class="time">This screen is not timed</span>
    </div>
    <div class="body">
      <div class="info">
        <p>
          This test measures the Reading, Writing, Listening and Speaking skills
          in English that you will need in an academic setting.
        </p>
        <p>
          - The test divided into 3 parts. Each part may contain a number of
          sections. The sections are individually timed.
        </p>
        <p>
          - The timer will be shown in the top tight corner of your screen. The
          number of items in the section will also be displayed.
        </p>
        <img
          src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_16194194135468807590.jpg"
          alt=""
        />
        <p>
          - The timer will become red when there is less than 5 minutes
          remaining for that section.
        </p>
        <p>
          - At the beginning of each part you will receive instructions. These
          will provide details on what to expect in that part of the test.
        </p>
        <p>
          - By clicking on the Next button at the bottom of each screen you
          confirm your answer and move to the next question. If you click on
          Next you will <span>not</span> be able to return to the previous
          question. You will <span>not</span> be able to revise any questions at
          the end of the test.
        </p>
        <p>
          - You will be offered a break of up to 10 minutes after Part 2. The
          break is optional.
        </p>
        <p>
          - This test makes use of different varieties of English, for example,
          British, American, Australian. You can answer in the standard English
          variety of your choice.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.SetTimeType({ type: 'NONE' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.check-headset {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebf2;
    padding: 30px 0 10px;
    > span {
      color: #1f1f40;
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    align-items: flex-start;
    .info {
      flex: 1;
      align-items: flex-start;
      margin-right: 20px;
      p {
        color: #1f1f40;
        font-size: 14px;
        line-height: 30px;
        > span {
          color: #ff606d;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
