import { post, get, getToken, postFormData } from './request'

export default {
  list: (p) => get('/api-web/exam/exam-list', p),
  meList: (p) => get('/api-web/exam/my-exam-list', p),
  detail: (p) => get('/api-web/exam/exam-info', p),
  submit: (p) => post('/api-web/exam/task-add', p),
  finish: (p) => post('/api-web/exam/task-finish', p),
  addExam: (p) => post('/api-web/exam/my-exam-add', p),
  examInfo: (p) => get('/api-web/exam/my-exam-info', p),
  resetExam: (p) => post('/api-web/exam/task-restart', p),
  deleteExam: (p) => post('/api-web/exam/task-del', p),
  saveExamIndex: (p) => post('/api-web/exam/task-mark-view', p),
  examScore: (p) => get('/api-web/exam/my-exam-score', p)
}
