<template>
  <div class="exam-content-di">
    <div class="title">
      Look at the graph below. In 25 seconds, please speak into the microphone
      and describe in detail what the graph is showing. You will have 40 seconds
      to give your response.
    </div>
    <div class="record-wrap">
      <div>
        <img :src="detail.img.url" alt="" />
      </div>
      <div>
        <Record
          ref="record"
          :time="25"
          :maxTime="40"
          :onUpload="handleUplaod"
          :onTime="handleRecordTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Record from '../../components/record'
import api from '@/api/exam'

export default {
  components: {
    Record
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      recordCurrentTime: -1
    }
  },
  mounted() {
    this.SetTimeType({ type: 'SPEAK' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (
        this.recordCurrentTime === 0 &&
        this.$refs.record &&
        this.$refs.record.stop
      ) {
        this.$refs.record.stop()
        return true
      }

      return false
    },

    handleRecordTime(time) {
      this.recordCurrentTime = time
    },

    async handleUplaod(url) {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        oss_url: url
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-di {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 65px;
      height: 330px;
    }
  }
}
</style>
