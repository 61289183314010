<template>
  <div class="exam-content-rl">
    <div class="title">
      You will hear a lecture. After listening to the lecture, in 10 seconds,
      please speak into the microphone and retell what you have just heard from
      the lecture in your own words. You will have 40 seconds to give your
      response.
    </div>
    <div class="record-wrap">
      <div v-if="detail.img">
        <img :src="detail.img.url" alt="" />
      </div>
      <div>
        <div class="audio">
          <Audio
            ref="record"
            :url="detail.audio.url"
            :time="3"
            :onInit="handleAudioInit"
          />
        </div>
        <div class="record">
          <Record
            v-if="recoredWaitTime > 0"
            :time="recoredWaitTime"
            :maxTime="40"
            :onUpload="handleUplaod"
            :onTime="handleRecordTime"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Record from '../../components/record'
import Audio from '../../components/audio'
import api from '@/api/exam'

export default {
  components: {
    Record,
    Audio
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      recoredWaitTime: 0,
      recordCurrentTime: -1
    }
  },
  mounted() {
    this.SetTimeType({ type: 'SPEAK' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (
        this.recordCurrentTime === 0 &&
        this.$refs.record &&
        this.$refs.record.stop
      ) {
        this.$refs.record.stop()
        return true
      }

      return false
    },

    handleRecordTime(time) {
      this.recordCurrentTime = time
    },

    handleAudioInit(audioTime, waitTime) {
      this.recoredWaitTime = Math.round(audioTime + waitTime + 10)
    },
    async handleUplaod(url) {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        oss_url: url
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-rl {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 65px;
      width: 260px;
    }
    .audio {
      margin-top: 24px;
    }
  }

  .record {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}
</style>
