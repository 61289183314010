<template>
  <div class="check-headset">
    <div class="title">
      <span>Part 1: Overview</span>
      <span class="time">This screen is not timed</span>
    </div>
    <div class="body">
      <div class="info">
        <p>
          In Part 1 you are tested mainly on your speaking and writing skills.
        </p>
        <img
          src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1619938741338297235807.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.SetTimeType({ type: 'NONE' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.check-headset {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebf2;
    padding: 30px 0 10px;
    > span {
      color: #1f1f40;
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    align-items: flex-start;
    .info {
      flex: 1;
      align-items: flex-start;
      margin-right: 20px;
      p {
        color: #1f1f40;
        font-size: 14px;
        line-height: 30px;
        > span {
          color: #ff606d;
          font-weight: bold;
        }
      }
      img {
        margin: 10px 0;
      }
    }
  }
}
</style>
