<template>
  <div class="exam-content-rs">
    <div class="title">
      You will hear a sentence. Please repeat the sentence exactly as you hear
      it. You will hear the sentence only once.
    </div>
    <div class="audio">
      <Audio :time="3" :url="detail.audio.url" :onInit="handleAudioInit" />
    </div>
    <div class="record">
      <Record
        ref="record"
        v-if="recoredWaitTime > 0"
        :time="recoredWaitTime"
        :maxTime="15"
        :onUpload="handleUplaod"
        :onTime="handleRecordTime"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Record from '../../components/record'
import Audio from '../../components/audio'
import api from '@/api/exam'

export default {
  components: {
    Record,
    Audio
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      recoredWaitTime: 0,
      recordCurrentTime: -1
    }
  },
  mounted() {
    this.SetTimeType({ type: 'SPEAK' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (
        this.recordCurrentTime === 0 &&
        this.$refs.record &&
        this.$refs.record.stop
      ) {
        this.$refs.record.stop()
        return true
      }
      return false
    },

    handleRecordTime(time) {
      this.recordCurrentTime = time
    },

    handleAudioInit(audioTime, waitTime) {
      this.recoredWaitTime = Math.round(audioTime + waitTime)
    },
    async handleUplaod(url) {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        oss_url: url
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-rs {
  color: #1f1f40;
  padding-bottom: 24px;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}
</style>
