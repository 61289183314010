<template>
  <div class="check-headset">
    <div class="title">
      <span>Start Exam</span>
      <span class="time">This screen is not timed</span>
    </div>
    <div class="body">
      <div class="info">
        <p>You are about to begin Part 1 of the Exam: Speaking and Writing</p>
        <p>Time allowed: 77 - 93 minutes</p>
        <p>
          <b>Remember</b>: to put your headphones on before beginning this
          section.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.SetTimeType({ type: 'START' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.check-headset {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebf2;
    padding: 30px 0 10px;
    > span {
      color: #1f1f40;
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    align-items: flex-start;
    .info {
      flex: 1;
      align-items: flex-start;
      margin-right: 20px;
      p {
        color: #1f1f40;
        font-size: 14px;
        line-height: 30px;
        > span {
          color: #ff606d;
          font-weight: bold;
        }
      }
      img {
        margin: 10px 0;
      }
    }
  }
}
</style>
