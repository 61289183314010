<template>
  <div class="exam-detail-finish">
    <div class="content">
      <div class="icon"><i class="iconfont icon-finish"></i></div>
      <div class="msg">{{ i18n.Mock.submitted }}</div>

      <div class="btn primary" @click="handleDetail">
        {{ i18n.Mock.ratings }}
      </div>
      <div class="btn" @click="handleClose">{{ i18n.Mock.ClosePage }}</div>
    </div>

    <el-dialog
      :visible.sync="visible"
      :title="i18n.Mock.Free"
      width="430px"
      class="exam-detail-finish-dialog"
    >
      <div class="tip">
        {{ i18n.Mock.watch }}
      </div>
      <div class="content">
        <img :src="helperInfo.site_val" alt="" />
        <div style="margin-top: 10px;">{{ helperInfo.remark }}</div>
      </div>
      <div class="btn-wrap">
        <div class="btn" @click="visible = false">{{ i18n.Mock.got }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      visible: true
    }
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    }),
    i18n() {
      return this.$t('messages')
    }
  },
  methods: {
    handleClose() {
      this.visible = false
      window.close()
    },
    handleDetail() {
      const { id, examId } = this.$route.query
      this.$router.push({
        name: 'examDetail',
        query: {
          id: id,
          examId: examId
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exam-detail-finish {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  .content {
    text-align: center;
    .icon {
      > i {
        font-size: 80px;
        border-radius: 50%;
        color: #36c626;
      }
    }
    .msg {
      color: #1f1f40;
      font-size: 16px;
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 60px;
    }
    .btn {
      background-color: #dcdcdc;
      border-radius: 5px;
      padding: 10px 28px;
      margin-top: 14px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &.primary {
        color: #fff;
        background-color: #3b86ff;
      }
    }
  }
}
.exam-detail-finish-dialog {
  .tip {
    color: #1f1f40;
  }
  .content {
    margin-top: 10px;
    img {
      width: 118px;
      height: 118px;
    }
    > div {
      font-size: 16px;
      line-height: 22px;
      color: #9c9c9c;
    }
  }
  .btn-wrap {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    border-radius: 5px;
    background-color: #3b86ff;
    padding: 10px 28px;
    color: #fff;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
