<template>
  <div class="check-headset">
    <div class="title">
      <span>Optional Break</span>
    </div>
    <div class="body">
      <div class="info">
        <p>
          You may now take a break of up to 10 minutes. If you want to take a
          break you <span>must</span> leave the testing room.
        </p>
        <p>
          <span
            >You should allow up to 5 minutes to sign back in and be seated.
          </span>
          If you spend more than 10 minutes in total on the break, that time is
          taken from your next part.
        </p>
        <br />
        <p>
          - If you want to take a break raise your hand to get the attention of
          the Test Administrator.
        </p>
        <p>
          - If you do <span>not</span> want to take a break click on the Next
          button below to continue the test.
        </p>
        <img
          src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1619990971262399375294.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.SetTimeType({ type: 'OPTIONALBREAK' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.check-headset {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebf2;
    padding: 30px 0 10px;
    > span {
      color: #1f1f40;
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    align-items: flex-start;
    .info {
      flex: 1;
      align-items: flex-start;
      margin-right: 20px;
      p {
        color: #1f1f40;
        font-size: 14px;
        line-height: 30px;
        > span {
          color: #ff606d;
          font-weight: bold;
        }
      }
      img {
        margin: 10px 0;
      }
    }
  }
}
</style>
