<template>
  <div class="exam-content-swt">
    <div class="title">
      Read the passage below and summarize it using one sentence. Type your
      response in the box at the bottom of the screen. You have 10 minutes to
      finish this task. Your response will be judged on the quality of your
      writing and on how well your response presents the key points in the
      passage.
    </div>
    <div class="body">
      <div>
        <pre v-html="(detail.content_text || '').replace(/[\[\]]/g, '')"></pre>
      </div>
      <div class="ipt">
        <Textarea v-model="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Textarea from '../../components/textarea'
import api from '@/api/exam'

export default {
  components: {
    Textarea
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    this.SetTimeType({ type: 'WRITE' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (!!this.content) {
        this.handleSave()
        return true
      }
      return false
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [this.content]
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-swt {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    width: 1014px;
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ipt {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}
</style>
