<template>
  <div class="exam-com-ipt">
    <div class="ipt">
      <textarea ref="ipt" v-model="iptText" />
    </div>
    <div class="btns">
      <span @click="handleCut">Cut</span>
      <span @click="handleCopy">Copy</span>
      <span @click="handlePaste">Paste</span>
    </div>
    <div class="count">Total Word Count: {{ wordCount }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    onInput: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      iptText: '',
      copyText: ''
    }
  },
  computed: {
    wordCount() {
      return this.iptText.split(/\s/gi).filter((m) => !!m).length
    }
  },
  watch: {
    value() {
      if (this.value !== this.iptText) {
        this.iptText = this.value
      }
    },
    iptText() {
      this.$emit('input', this.iptText)
      this.onInput(this.iptText)
    }
  },
  methods: {
    handleCut() {
      this.copyText = this.getSelectText()
      this.$refs.ipt.focus()
      document.execCommand('cut')
    },
    handleCopy() {
      this.copyText = this.getSelectText()
      this.$refs.ipt.focus()
      document.execCommand('copy')
    },
    handlePaste() {
      this.$refs.ipt.focus()
      const iptEl = this.$refs.ipt
      this.iptText =
        this.iptText.slice(0, iptEl.selectionStart) +
        this.copyText +
        this.iptText.slice(iptEl.selectionStart)
    },
    getSelectText() {
      const iptEl = this.$refs.ipt
      if (window.getSelection) {
        if (iptEl.selectionStart && iptEl.selectionEnd) {
          return this.iptText.substring(
            iptEl.selectionStart,
            iptEl.selectionEnd
          )
        } else {
          return ''
        }
      } else {
        return document.selection.createRange().text
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exam-com-ipt {
  width: 100%;
  .ipt {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #7c8084;
    padding: 18px 20px;
    > textarea {
      width: 100%;
      height: 100px;
      border: 0;
    }
  }
  .btns {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    > span {
      width: 100px;
      height: 28px;
      border-radius: 5px;
      border: 1px solid #7c8084;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }
  .count {
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
  }
}
</style>
