<template>
  <div class="check-headset">
    <div class="title">
      <span>Speaking Instructions</span>
      <span class="time">This screen is not timed</span>
    </div>
    <div class="body">
      <div class="info">
        <p>
          Some questions require you to first listen to an audio clip and then
          record your answer by speaking clearly into the microphone.
        </p>
        <div>
          <label>Listening: </label>
          <div>
            <p>
              The <span>Current Status</span> shows how long you have until the
              audio clip starts.
            </p>
            <Audio />
          </div>
          <div>
            <p>
              A blue bar shows the progress of the audio clip. When it reaches
              the right hand side the audio clip will stop playing.
            </p>
            <div class="row">
              <Audio :progress="60" />
              <Audio :progress="100" />
            </div>
          </div>
        </div>
        <div>
          <label>Speaking: </label>
          <div>
            <p>
              The <span>Current Status</span> shows how long you have until the
              microphone opens. Start speaking when the status changes to
              <span>Recording</span>.
            </p>
            <Record statusText="Beginning in 30 seconds" />
          </div>
          <div>
            <p>
              You must finish before the progress bar reaches the right hand
              side. If you remain silent for longer than 3 seconds, the
              recording will stop. You will <span>not</span> be able to
              re-record.
            </p>
            <div class="row">
              <Record statusText="Recording" />
              <Record statusText="Completed" :progress="100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Record from '../components/recordedStyle'
import Audio from '../components/audioStyle'

export default {
  components: {
    Record,
    Audio
  },

  mounted() {
    this.SetTimeType({ type: 'NONE' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.check-headset {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebf2;
    padding: 30px 0 10px;
    > span {
      color: #1f1f40;
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    align-items: flex-start;
    .info {
      flex: 1;
      align-items: flex-start;
      margin-right: 20px;
      > div {
        display: flex;
        color: #1f1f40;
        font-size: 14px;
        margin-top: 10px;
        > label {
          font-weight: bold;
          line-height: 20px;
          margin-top: 10px;
        }
        > div {
          flex: 1;
          &:nth-child(n + 3) {
            margin-left: 80px;
          }
          > p {
            margin-bottom: 10px;
          }
        }
      }
      .row {
        display: flex;
        > div {
          margin-right: 46px;
        }
      }
      p {
        color: #1f1f40;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        > span {
          color: #ff606d;
          font-weight: bold;
        }
      }
      img {
        margin: 10px 0;
      }
    }
  }
}
</style>
