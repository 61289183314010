/**
 * 单词计算空格
 * @param greRecorder 单词总列表
 * @param item 单词对象 {text: string}
 * @param index 当前位置
 * @returns {boolean} 是否需要空格
 */
export const wordSpaceCalc = (greRecorder, item, index) => {
  if (item.interval) {
    return false
  }

  // "'“ 左边匹配
  if (/["'“]/.test(item.text)) {
    const longText = greRecorder
      .filter((m, i) => i < index)
      .map((m) => m.text)
      .join(' ')
    const matchArr = longText.match(/["'“]/g)
    const count = matchArr ? matchArr.length : 0
    return count > 0 ? count % 2 === 0 : false
  }

  // 特殊字符
  if (/[-–—’']$/g.test(item.text)) {
    return false
  }

  // 更特殊情况 -//xx  xx//-
  if (greRecorder[index + 1] && greRecorder[index + 1].interval) {
    const reg = /^[-–—]/
    if (
      /[-–—]$/.test(item.text) ||
      (greRecorder[index + 2] && reg.test(greRecorder[index + 2].text)) ||
      (greRecorder[index + 3] && reg.test(greRecorder[index + 3].text))
    ) {
      return false
    }
  }

  if (greRecorder[index + 1]) {
    if (greRecorder[index + 1].lang === 'en') {
      return true
    }

    if (/[0-9]/g.test(greRecorder[index + 1].text)) {
      return !/[0-9,$]/.test(item.text)
    }

    if (
      greRecorder[index + 1].lang !== 'en' &&
      !greRecorder[index + 1].interval &&
      !/[$]/g.test(greRecorder[index + 1].text)
    ) {
      return false
    }
  }

  return true
}

const symbolMap = [
  {
    reg: /。/g,
    value: '.'
  },
  {
    reg: /？/g,
    value: '?'
  },
  {
    reg: /！/g,
    value: '!'
  },
  {
    reg: /，/g,
    value: ','
  },
  {
    reg: /；/g,
    value: ';'
  },
  {
    reg: /：/g,
    value: ':'
  },
  {
    reg: /[“”]/g,
    value: '"'
  },
  {
    reg: /[—]/g,
    value: '-'
  },
  {
    reg: /（/g,
    value: '('
  },
  {
    reg: /）/g,
    value: ')'
  },
  {
    reg: /([\n\r])/g,
    value: '$1 '
  }
]
export const symbolsFix = (str) => {
  let result = str
  symbolMap.forEach((item) => {
    result = str.replace(item.reg, item.value)
  })
  return result
}
