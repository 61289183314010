<template>
  <div class="exam-content-rfib">
    <div class="title">
      Below is a text with blanks. Click on each blank, a list of choices will
      appear. Select the appropriate answer choice for each blank.
    </div>
    <div class="body">
      <div class="content">
        <template v-for="(item, index) in textContentList">
          <span :key="index">
            <template v-if="item.text.indexOf('_') < 0">
              <span :style="item.lang == 'default' ? 'margin:0' : ''">
                {{ item.text }}
              </span>
              <span v-html="'&nbsp;'"></span>
            </template>
            <template v-if="item.text.indexOf('_') >= 0">
              <draggable
                class="dragArea"
                :list="greFillSelected[item.count]"
                ghost-class="ghost"
                group="people"
                :filter="'.fill-text'"
              >
                <span class="fill-text"
                  >{{ (greFillSelected[item.count][0] || {}).text }}
                  <i
                    class="el-icon-circle-close"
                    v-if="greFillSelected[item.count][0]"
                    @click="clear(item.count)"
                  ></i>
                </span>
              </draggable>
            </template>
          </span>
          <br v-if="/[\n\r]/g.test(item.text)" />
        </template>
      </div>
      <draggable
        class="fillList"
        :list="greFillOption"
        :group="{ name: 'people', pull: 'clone', put: false }"
        :filter="'.disabled'"
        @end="end"
        @start="choose"
      >
        <div
          class="fill-item"
          :class="{
            disabled:
              selectDraggableIndex >= 0 && index !== selectDraggableIndex
          }"
          v-for="(items, index) of greFillResultOption"
          :key="items.text"
        >
          <span
            class="draggable-item"
            v-if="!fillSelected.find((m) => m && m.text === items.text)"
          >
            {{ items.text }}
          </span>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api/exam'
import { symbolsFix } from '@/utils/parctice'
import draggable from 'vuedraggable'
import { cloneDeep } from 'lodash'

export default {
  components: {
    draggable
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      greFillSelected: [],
      greFillOption: [],
      greFillResultOption: [],
      selectDraggableIndex: -1
    }
  },
  computed: {
    textContentList() {
      if (!this.detail.content_text) {
        return []
      }
      const content = symbolsFix(this.detail.content_text)

      const answerList = (this.answerDetail
        ? this.answerDetail.answer.article
        : []
      ).filter((m) => /_/g.test(m.text))
      let optsCount = -1
      return content
        .replace(/\r.?\n/g, '\n')
        .split(/[^\S\r\n]/g)
        .map((text) => {
          const isLine = /_/g.test(text)
          if (isLine) {
            optsCount += 1
          }
          const answerData = answerList[optsCount] || {}
          return {
            is_underline: isLine,
            lang: isLine ? 'default' : 'en',
            text: text,
            ...(isLine ? { count: optsCount } : {}),
            ...(isLine ? answerData : {})
          }
        })
    },
    fillSelected() {
      return this.greFillSelected.map((item) => item[0])
    }
  },
  watch: {
    textContentList: {
      immediate: true,
      handler() {
        this.textContentList.forEach((item) => {
          if (/_/g.test(item.text)) {
            this.greFillSelected.push([])
          }
        })
      }
    },
    detail: {
      immediate: true,
      handler() {
        this.greFillOption = this.detail.content.choose.map((item) => ({
          text: item
        }))
        this.greFillResultOption = cloneDeep(this.greFillOption)
      }
    }
  },
  mounted() {
    this.SetTimeType({ type: 'READ' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (this.greFillSelected.reduce((flag, item) => flag || !!item, false)) {
        this.handleSave()
        return true
      }
      return false
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: this.greFillSelected.map((item) =>
          item[0] ? item[0].text : null
        )
      })
      this.onFinish()
    },

    clear(index) {
      this.greFillSelected.splice(index, 1, [])
    },
    end() {
      this.selectDraggableIndex = -1
    },
    choose(w) {
      this.selectDraggableIndex = w.oldIndex
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-rfib {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    width: 1014px;
    margin: 20px auto;
    .content {
      & > span {
        line-height: 24px;
        display: inline-block;
        vertical-align: middle;
      }
      .fill-text {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 180px;
        padding-left: 10px;
        box-sizing: border-box;
        height: 20px;
        line-height: 16px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(112, 112, 112, 1);
        &:hover {
          .el-icon-circle-close {
            visibility: visible;
          }
        }
        &.green {
          color: #36c626;
        }
        &.red {
          color: #ff1a2e;
        }
        .el-icon-circle-close {
          position: absolute;
          visibility: hidden;
          right: 2px;
          top: 1px;
          cursor: pointer;
        }
      }
      .face {
        color: #36c626;
        margin-left: 5px;
      }
    }
  }
  .dragArea {
    width: 182px;
    height: 24px;
    overflow: hidden;
    line-height: 20px;
    margin: 0 5px;
    .fill-text {
      margin: 0;
    }
  }
  .fillList {
    margin: 87px auto 0;
    padding: 17px 20px 0;
    width: 812px;
    background: rgba(198, 220, 255, 0.5);
    border-radius: 10px;
    .fill-item {
      display: inline-block;
      width: 33.33%;
      margin-bottom: 20px;
      text-align: center;
      height: 22px;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      width: 180px;
      height: 20px;
      line-height: 20px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(112, 112, 112, 1);
      cursor: pointer;
    }
    .sortable-ghost {
      cursor: pointer;
    }
  }
}
</style>
