<template>
  <div class="exam-content-sst">
    <div class="title">
      You will hear a short report. Write a summary for a fellow student who was
      not present. You should write 50-70 words. You have 10 minutes to finish
      this task. Your response will be judged on the quality of your writing and
      on how well your response presents the key points presented in the
      lecture.
    </div>
    <div class="body">
      <div class="audio">
        <Audio :url="detail.audio.url" :time="12" />
      </div>
      <div class="ipt">
        <Textarea v-model="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api/exam'
import Textarea from '../../components/textarea'
import Audio from '../../components/audio'

export default {
  components: {
    Textarea,
    Audio
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    infoData: {
      type: Object,
      default: () => ({})
    },

    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    this.SetTimeType({ type: 'LISTEN' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType']),
    submit() {
      if (!!this.content) {
        this.handleSave()
        return true
      }
      return false
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [this.content]
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-sst {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    width: 1014px;
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ipt {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 14px;
    display: flex;
    justify-content: center;
  }
}
</style>
