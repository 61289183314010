<template>
  <div class="exam-com-radio">
    <el-radio
      v-for="(item, index) of list"
      :key="`${index}_${item.text}`"
      v-model="value"
      :label="index"
    >
      {{ index | indexChat }}{{ item.text }}
    </el-radio>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    onInput: {
      type: Function,
      default: () => {}
    }
  },
  filters: {
    indexChat(index) {
      return `${String.fromCharCode(index + 65)}) `
    }
  },
  data() {
    return {
      value: -1
    }
  },
  watch: {
    value() {
      this.onInput([this.value])
    }
  }
}
</script>

<style scoped lang="scss">
.exam-com-radio {
  ::v-deep .el-radio {
    width: 100%;
    margin: 8px 0;
    &:hover {
      background-color: #fff781;
    }
    .el-radio__label {
      display: inline-block;
      white-space: normal;
      word-break: break-word;
      vertical-align: text-top;
      width: 90%;
    }
    &.is-checked,
    &:active,
    &:focus {
      background-color: #fff781;
      .el-radio__inner {
        background-color: #3b86ff;
        border-color: #3b86ff;
      }
      .el-radio__label {
        color: #606266;
      }
    }
  }
}
</style>
