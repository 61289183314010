<template>
  <div class="exam-footer">
    <div class="body">
      <div>
        <el-button
          v-if="stepInfo.step <= stepInfo.total - 2"
          type="primary"
          @click="handleBack"
          >Pause and quit</el-button
        >
      </div>
      <div>
        <!--        <el-button type="primary" @click="onPrev" v-if="stepInfo.step > 0">-->
        <!--          Previous-->
        <!--        </el-button>-->
        <el-button
          type="primary"
          @click="handleNext"
          v-if="stepInfo.step < stepInfo.total - 2"
          :loading="loading"
        >
          Next
        </el-button>
        <el-button
          type="primary"
          @click="onFinish"
          v-else-if="stepInfo.step == stepInfo.total - 2"
          :loading="loading"
        >
          Finish
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    stepInfo: {
      type: Object,
      default: () => ({})
    },
    onNext: {
      type: Function,
      default: () => {}
    },
    onPrev: {
      type: Function,
      default: () => {}
    },
    onFinish: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState('exam', {
      loading: (state) => state.loading
    })
  },
  created() {
    window.addEventListener('beforeunload', this.beforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },
  methods: {
    beforeUnload: (e) => {
      const msg = 'Save and Exit the current exam?'
      const event = e || window.event
      event.returnValue = msg
      return msg
    },
    handleBack() {
      this.$confirm('Save and Exit the current exam?', 'Save and Exit', {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm'
      }).then(() => {
        window.close()
      })
    },
    handleNext() {
      this.onNext()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-footer {
  height: 90px;
  min-height: 90px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #4a4a58;
  .body {
    max-width: 1285px;
    width: 100%;
    margin: 16px auto 0;
    display: flex;
    justify-content: space-between;
    ::v-deep .el-button {
      background-color: #3b86ff;
      border-color: #3b86ff;
      height: 28px;
      line-height: 28px;
      padding: 0 20px;
      min-width: 100px;
      &.is-disabled {
        background-color: #dcdcdc;
        border-color: #dcdcdc;
      }
    }
  }
}
</style>
