<template>
  <div class="exam-audio-style">
    <div class="status">Status: Click Play to Begin</div>
    <div class="volume">
      <label>Volume</label>
      <div class="volume-slide">
        <div
          class="slide-circle"
          :style="{
            left: `${volume}%`
          }"
        ></div>
      </div>
    </div>
    <div class="progress">
      <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
    </div>
    <div class="btns" v-if="enabledTools">
      <div>Play</div>
      <div>Stop</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    volume: {
      type: Number,
      default: 50
    },
    progress: {
      type: Number,
      default: 0
    },
    enabledTools: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.exam-audio-style {
  width: 300px;
  border-radius: 5px;
  padding: 16px 20px;
  color: #1f1f40;
  font-size: 14px;
  border: 1px solid #a4afb7;
  box-sizing: border-box;
  .volume {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .volume-slide {
      flex: 1;
      margin-left: 20px;
      height: 2px;
      position: relative;
      background-color: #c7cade;
      .slide-circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #c7cade;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  .progress {
    margin-top: 20px;
    height: 10px;
    position: relative;
    background-color: #c7cade;
    border-radius: 2px;
    .progress-bar {
      background-color: #3b86ff;
      height: 10px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
