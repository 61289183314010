<template>
  <div class="exam-audio-style">
    <audio
      ref="audio"
      preload="auto"
      :src="url"
      @loadedmetadata="handleLoadedmetadata"
      @timeupdate="handleTimeupdate"
    />

    <div class="status">Status: Beginning in {{ countTime }} seconds</div>
    <div class="volume">
      <label>Volume</label>
      <el-slider
        :show-tooltip="false"
        class="slider"
        v-model="volume"
        :max="100"
        @input="handleVolume"
      />
    </div>
    <div class="progress">
      <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
    </div>
  </div>
</template>

<script>
const STATUS = {
  READY: 'ready',
  PLAY: 'play',
  PAUSE: 'pause',
  STOP: 'stop'
}
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    time: {
      type: Number,
      default: 30
    },

    onInit: {
      type: Function,
      default: () => {}
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    onEnd: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      countTime: 0,
      volume: 50,

      currentTime: 0,
      maxTime: 0,
      status: STATUS.READY // ready, play, pause, stop
    }
  },
  computed: {
    progress() {
      return (this.currentTime / this.maxTime) * 100
    }
  },
  mounted() {
    this.countTime = this.time
    this.cutDown()
  },
  beforeDestroy() {
    clearInterval(this.timeId)
  },
  methods: {
    cutDown() {
      this.timeId = setInterval(() => {
        this.countTime -= 1
        if (this.countTime === 0) {
          clearInterval(this.timeId)
          this.playAudio()
        }
      }, 1000)
    },

    playAudio() {
      if (this.status === STATUS.PLAY) {
        this.status = STATUS.PAUSE
        return this.$refs.audio.pause()
      }

      // 其他的音频暂停
      Array.from(document.querySelectorAll('audio')).forEach((el) => el.pause())

      this.$refs.audio.play()
      this.status = STATUS.PLAY
    },
    handleLoadedmetadata({ target }) {
      this.currentTime = 0
      this.maxTime = target.duration

      this.onInit(this.maxTime, this.countTime, this.$refs.audio, this)
    },
    handleTimeupdate({ target }) {
      this.currentTime = target.currentTime

      this.onChange(this.currentTime, this.maxTime, this.$refs.audio, this)

      if (this.currentTime >= this.maxTime) {
        this.status = STATUS.STOP

        this.onEnd(this.currentTime, this.maxTime, this.$refs.audio, this)
      }
    },
    handleVolume(value) {
      this.$refs.audio.volume = Math.round(value / 100)
    }
  }
}
</script>

<style scoped lang="scss">
.exam-audio-style {
  width: 300px;
  border-radius: 5px;
  padding: 16px 20px;
  color: #1f1f40;
  font-size: 14px;
  border: 1px solid #a4afb7;
  box-sizing: border-box;
  .volume {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .slider {
      flex: 1;
      margin-left: 20px;
      ::v-deep .el-slider__bar {
        background-color: #c7cade;
      }
      ::v-deep .el-slider__button {
        border-color: #c7cade;
      }
    }
  }
  .progress {
    margin-top: 20px;
    height: 10px;
    position: relative;
    background-color: #c7cade;
    border-radius: 2px;
    overflow: hidden;
    .progress-bar {
      background-color: #3b86ff;
      height: 10px;
      position: absolute;
      left: 0;
      top: 0;
      transition: width 0.3s linear;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    > div {
      width: 75px;
      height: 26px;
      border-radius: 5px;
      border: 1px solid #a4afb7;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
