<template>
  <el-dialog
    width="786px"
    :visible.sync="visible"
    class="audio-open-dialog"
    :before-close="handleClose"
  >
    <div class="title">{{ i18n.Pra.text12 }}</div>
    <div>{{ i18n.Pra.text13 }}</div>
    <div>{{ i18n.Pra.text14 }}</div>
    <img :src="require('@/assets/audio-tips.jpg')" alt />
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang='less'>
.audio-open-dialog {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1f1f40;
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__body {
    margin-top: -30px;
  }
  .title {
    padding-bottom: 8px;
    margin-bottom: 20px;
    color: #a4afb7;
    font-size: 14px;
    border-bottom: 1px solid #ebebf2;
  }
  img {
    display: block;
    margin: 50px auto 30px;
  }
}
</style>
