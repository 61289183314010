<template>
  <div class="exam-recorded-style">
    <div class="title">Recorded Answer</div>
    <div class="status">
      <label>Current status:</label>
      <div style="margin-top: 10px">{{ statusText }}</div>
    </div>
    <div class="progress">
      <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
    </div>
    <div class="btns" v-if="enabledTools">
      <div>Record</div>
      <div>Playback</div>
      <div>Stop</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    volume: {
      type: Number,
      default: 0
    },
    progress: {
      type: Number,
      default: 0
    },
    enabledTools: {
      type: Boolean,
      default: false
    },
    statusText: {
      type: String,
      default: 'Click Record to Begin'
    }
  }
}
</script>

<style scoped lang="scss">
.exam-recorded-style {
  width: 300px;
  border-radius: 5px;
  padding: 13px 20px;
  color: #1f1f40;
  font-size: 14px;
  border: 1px solid #a4afb7;
  background-color: #e8f1ff;
  box-sizing: border-box;
  .title {
    font-weight: bold;
    text-align: center;
  }
  .status {
    margin-top: 16px;
    > label {
      font-weight: bold;
    }
  }
  .progress {
    margin-top: 15px;
    height: 10px;
    position: relative;
    background-color: #c7cade;
    border-radius: 2px;
    .progress-bar {
      background-color: #3b86ff;
      height: 10px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    > div {
      width: 75px;
      height: 26px;
      border-radius: 5px;
      border: 1px solid #a4afb7;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #95c6ff;
    }
  }
}
</style>
