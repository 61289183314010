<template>
  <div class="exam-header">
    <div class="top">
      <div class="logo">
        <img src="@/assets/logo-home.png" />
      </div>
      <div>
        <div
          :class="{
            time: true,
            show: time.show,
            warning: time.warning
          }"
        >
          <i class="iconfont icon-time"></i>
          <span>Time Remaining {{ (time.current * 1000) | timeLen }}</span>
        </div>
        <div class="step" v-if="currentStepCount > -1">
          <i class="iconfont icon-menu"></i>
          <span>{{ currentStepCount + 1 }} of {{ examRealList.length }}</span>
        </div>
      </div>
    </div>
    <div class="top-bar"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    stepInfo: {
      type: Object,
      default: () => ({})
    },
    allTime: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('exam', {
      time: (state) => state.time
    }),
    examRealList() {
      return this.stepInfo.allStep.filter(
        (m) => m.data && m.data.item && m.data.item.id
      )
    },
    currentStepCount() {
      const currentStep = this.stepInfo.currentStep
      if (
        !currentStep ||
        !currentStep.data ||
        !currentStep.data.item ||
        !currentStep.data.item.id
      ) {
        return -1
      }

      return this.examRealList.findIndex(
        (m) => m.data.item.id === currentStep.data.item.id
      )
    }
  }
}
</script>

<style scoped lang="scss">
.exam-header {
  min-height: 91px;
  .top {
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4a4a58;
    padding: 0 75px;
    color: #fff;
    .time {
      display: flex;
      align-items: center;
      font-size: 14px;
      justify-content: flex-end;
      opacity: 0;
      > i {
        font-size: 14px;
        margin-right: 10px;
      }
      &.show {
        opacity: 1;
      }
      &.warning {
        > span {
          color: #ff606d;
        }
      }
    }
    .step {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #c9c9cd;
      font-size: 14px;
      margin-top: 8px;
      > i {
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
  .top-bar {
    background-color: #3b86ff;
    height: 25px;
  }
}
</style>
