<template>
  <div class="check-microphone">
    <div class="title">
      <span>Microphone Check</span>
      <span class="time">This screen is not timed</span>
    </div>
    <div class="body">
      <div class="info">
        <p>
          This is an opportunity to check that your headset is working
          correctly.
        </p>
        <p>
          1 Make sure your headset is on and the microphone is in the downward
          position near your mouth.
        </p>
        <p>
          2 When you are ready, click on the <span>Record</span> button and say
          <b>"Testing, testing, one, two, three"</b> into the microphone.
        </p>
        <p>
          3 After you have spoken, click on the <span>Stop</span> button. Your
          recording is now complete.
        </p>
        <p>
          4 Now click on the <span>Playback</span> button. You should clearly
          hear yourself speaking.
        </p>
        <p>
          5 If you can not hear your voice clearly, please check the following:
        </p>
        <p>
          - Headphone and microphone jacks are plugged into the computer
          completely and correctly.
        </p>
        <p>- Volume control on the headset or headset cord.</p>
        <p>- Volume control settings on the computer.</p>
        <p>
          If you have checked all the these items and are still having trouble,
          your headset or computer system may not be operating correctly. Please
          contact our customer service support.
        </p>
        <RecordedStyle class="recorded" />
        <p>
          Note: During the mock you will not have <span>Record</span>,
          <span>Playback</span> and <span>Stop</span> buttons. The voice
          recording will start automatically.
        </p>
      </div>
      <img
        src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1619415863081354202283.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import RecordedStyle from '../components/recordTest'

export default {
  components: {
    RecordedStyle
  },

  mounted() {
    this.SetTimeType({ type: 'NONE' })
  },
  methods: {
    ...mapActions('exam', ['SetTimeType'])
  }
}
</script>

<style scoped lang="scss">
.check-microphone {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebf2;
    padding: 30px 0 10px;
    > span {
      color: #1f1f40;
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    align-items: flex-start;
    > img {
      min-width: 214px;
    }
    .info {
      flex: 1;
      margin-right: 20px;
      .recorded {
        margin-top: 10px;
        margin-bottom: 20px;
      }
      p {
        color: #1f1f40;
        font-size: 14px;
        line-height: 30px;
        > span {
          color: #ff606d;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
