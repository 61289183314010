<template>
  <el-checkbox-group class="exam-com-checkbox" v-model="checkList">
    <el-checkbox
      v-for="(item, index) of list"
      :key="`${index}_${item.text}`"
      :label="index"
    >
      {{ index | indexChat }}{{ item.text }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    onInput: {
      type: Function,
      default: () => []
    }
  },
  filters: {
    indexChat(index) {
      return `${String.fromCharCode(index + 65)}) `
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  watch: {
    checkList() {
      this.onInput(this.checkList)
    }
  }
}
</script>

<style scoped lang="scss">
.exam-com-checkbox {
  ::v-deep .el-checkbox {
    width: 100%;
    margin: 8px 0;
    &:hover {
      background-color: #fff781;
    }
    .el-checkbox__label {
      display: inline-block;
      white-space: normal;
      word-break: break-word;
      vertical-align: text-top;
      width: 90%;
    }
    &.is-checked,
    &:active,
    &:focus {
      background-color: #fff781;
      .el-checkbox__inner {
        background-color: #fff;
        border-color: #3b86ff;
        color: #3b86ff;
        border-width: 2px;
        &:after {
          border-color: #3b86ff;
          border-width: 2px;
          left: 3px;
          top: 0px;
        }
      }
      .el-checkbox__label {
        color: #606266;
      }
    }
  }
}
</style>
