<template>
  <div class="page-exam">
    <Header :allTime="allTime" :stepInfo="stepInfo" />
    <div class="body">
      <div>
        <Detail
          v-if="info.id"
          ref="detail"
          :examInfo="examInfo"
          :info="info"
          :onStep="handleStep"
          :examDetail="examDetail"
        />
      </div>
    </div>
    <Footer
      :stepInfo="stepInfo"
      :onNext="handleNextStep"
      :onPrev="handlePrevStep"
      :onFinish="handleFinish"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Header from './header'
import Footer from './footer'
import Detail from './detail'

import api from '@/api/exam'

export default {
  components: {
    Header,
    Footer,
    Detail
  },
  data() {
    return {
      info: {},
      examInfo: {},
      examDetail: {},

      stepInfo: {
        step: 0,
        total: 0,
        currentStep: null,
        allStep: []
      },

      allTime: 0
    }
  },
  created() {
    this.fetchListDetail()
    this.fetchInfo()

    this.examInfo = {
      my_exam_id: this.$route.query.examId
    }

    // this.countdown()
  },
  beforeDestroy() {
    clearInterval(this.allTimeId)
  },
  methods: {
    ...mapActions('exam', ['SetInfo']),
    async fetchListDetail() {
      const { data } = await api.detail({
        exam_id: this.$route.query.id
      })
      this.info = {
        ...data
      }

      this.SetInfo(this.info)
    },
    async fetchInfo() {
      if (!this.$route.query.examId) {
        return false
      }

      const { data } = await api.examInfo({
        my_exam_id: this.$route.query.examId
      })
      if (data.last_view_id > 0) {
        this.$confirm('Click "Continue" to resume the exam', 'Resume', {
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Continue'
        }).then(() => {
          this.examDetail = {
            ...data
          }
        })
      }
    },

    countdown() {
      this.allTime = 0
      clearInterval(this.allTimeId)
      this.allTimeId = setInterval(() => {
        this.allTime += 1
      }, 1000)
    },

    handleNextStep() {
      this.$refs.detail.nextStep()
    },
    handlePrevStep() {
      this.$refs.detail.prevStep()
    },
    handleStep({ step, total, currentStep, allStep }) {
      this.stepInfo = {
        step: step,
        total: total,
        currentStep,
        allStep
      }
    },
    handleFinish() {
      this.$refs.detail.saveFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.page-exam {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .body {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    > div {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}
</style>
